import Alpine from 'alpinejs'
  import axios from 'axios';

  const isEmail = (value) => {
    return new RegExp('^\\S+@\\S+[\\.][0-9a-z]+$').test(
      String(value).toLowerCase(),
    );
  };

  const submitMessage = (page, data) => {
    const contactForm = document.querySelector('form');
    const submitButton = document.querySelector('#contact-submit')
    let body;

    switch (page) {
      case 'general':
        if (
          !data.name ||
          data.name.length == 0 ||
          !data.email ||
          !isEmail(data.email) ||
          !data.subject ||
          data.subject.length == 0 ||
          !data.message ||
          data.message.length == 0
        ) {
          return;
        } else {
          body = {
            page,
            name: data.name,
            email: data.email,
            subject: data.subject,
            message: data.message,
          };
        }
        break;
      case 'for-shops':
        if (
          !data.name ||
          data.name.length == 0 ||
          !data.email ||
          !isEmail(data.email) ||
          !data.region ||
          data.region.length == 0 ||
          !data.type ||
          data.type.length == 0 ||
          !data.message ||
          data.message.length == 0
        ) {
          return;
        } else {
          body = {
            page,
            name: data.name,
            email: data.email,
            region: data.region,
            type: data.type,
            message: data.message,
          };
        }
        break;
      case 'for-brands':
        if (
          !data.name ||
          data.name.length == 0 ||
          !data.email ||
          !isEmail(data.email) ||
          !data.message ||
          data.message.length == 0
        ) {
          return;
        } else {
          body = {
            page,
            name: data.name,
            email: data.email,
            message: data.message,
          };
        }
        break;
    }

    submitButton.disabled = true

    axios
      .post('https://www.presentda.be/.netlify/functions/contact-mail', { body })
      .then((response) => {
        contactForm.reset();
        submitButton.disabled = false
        return response;
      })
      .catch((error) => {
        submitButton.disabled = false
        return error;
      });
  };

  Alpine.store('isEmail', isEmail);
  Alpine.store('submitMessage', submitMessage);

  // document.addEventListener('alpine:init', () => {
  //   Alpine.data('mobileMenu', () => ({
  //     openMobile: false,

  //     toggle() {
  //       this.openMobile = !this.openMobile
  //     },
  //   }))
  // })